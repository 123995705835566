/* eslint-disable */
import React, {useState} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Row, Col, Button } from 'react-bootstrap'
import './styled.css'
import { Link } from 'gatsby'
import Form from 'react-bootstrap/Form'
import FormCheck from 'react-bootstrap/FormCheck'
import FormControl from 'react-bootstrap/FormControl'
import axios from "axios"
import { navigate } from 'gatsby'

const Meeting = ({location}) => {
  const [isGoing, setIsGoing] = useState(true)
  const handleInputChange = (event) => {
    if(isGoing === true) {
      setIsGoing(false);
      setInputs(prev => ({
        ...prev,
        communication: false
      }));
    } else {
      setIsGoing(true);
      setInputs(prev => ({
        ...prev,
        communication: true
      }));
    }
  };


  /* NEW: Input state handling vvvv */
  const [inputs, setInputs] = useState({
    companyName: "",
    name: "",
    email: "",
    mobile: "",
    communication: "",
    location: location
  });

  const handleOnChange = event => {
    event.persist();
    setInputs(prev => ({
      ...prev,
      [event.target.id]: event.target.value
    }));

  };
  /* End input state handling ^^^^ */

  // Server state handling
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null
  });
  const handleServerResponse = (ok, msg) => {
    setServerState({
      submitting: false,
      status: { ok, msg }
    });
    if (ok) {
      setInputs({
        companyName: "",
        name: "",
        email: "",
        mobile: "",
        communication:"",
      });
    }
  };
  const handleOnSubmit = event => {
    event.preventDefault();
    setServerState({ submitting: true });
    axios({
      method: "POST",
      url: "https://formspree.io/f/xknpogek",
      data: inputs
    })
      .then(r => {
        handleServerResponse(true, "Thanks!");
        navigate('/thank-you');
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error);
      });
  };
  return (
    <div className="meeting-form-sidebar">
      <h3>Want to set up a meeting?</h3>
      <p>
        Please enter your details here and we'll be in touch to arrange a time
      </p>
      <Form
        name="Meeting Form"
        method="post"
        onSubmit={handleOnSubmit}
      >
        <input type="hidden" name="form-name" value="Meeting" />
        <Form.Group>
          <Form.Label>Company or project name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Company or project name"
            name="companyName"
            value={inputs.companyName}
            onChange={handleOnChange}
            id="companyName"
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Name and surname pretty please"
            name="Name"
            value={inputs.name}
            onChange={handleOnChange}
            id="name"
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Your company email address"
            name="Email"
            value={inputs.email}
            onChange={handleOnChange}
            id="email"
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Phone number</Form.Label>
          <Form.Control
            type="text"
            placeholder="Mobile number if possible please"
            name="mobile"
            value={inputs.mobile}
            onChange={handleOnChange}
            id="mobile"
          />
        </Form.Group>
        <div className="mt-4 footer-meeting-row-align checkbox-d ">
          {['checkbox'].map(type => (
            <div className="comms" key={`custom-${type}`}>
              <Form.Check
                checked={isGoing}
                onChange={handleInputChange}
                type={type}
                id="communication"
                label={`I want to receive ongoing communication `}
                name="communication"
              />
            </div>
          ))}
          <button type="submit" className="styled__Button-ksqKNN iyLmoo styled__Button-kGpwPX dQtCFI form-contact-button">
            Send
            <svg
              className="Arrow__Svg-gllSXh SARKW styled__Arrow-ihTfeJ cFLifL"
              width="14"
              height="10"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g stroke="#030303" fill="none" fillRule="evenodd">
                <path d="M8.5.964L13.036 5.5 8.5 10.036"></path>
                <path d="M12.5 5.5H.5" strokeLinecap="square"></path>
              </g>
            </svg>
          </button>
        </div>
        <small>
          Once you are signed up to our marketing communication. you can
          unsubscribe and update your preferences at anytime. We'll share our
          news, blogs, and invitations to our events.
          <a title="View our Privacy Policy" target="_blank" rel="noopener noreferrer" href="https://www.iubenda.com/privacy-policy/20841623">View our Privacy Policy</a> to find out more about how we take care of your personal data.
        </small>
      </Form>
    </div>
  )
}


export default Meeting
